* {
  background-color: var(--body-bg-color);
  color: var(--font-color);
  cursor: none;
}

body {
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cursor-dark {
  position: fixed;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border-radius: 16px;
  background-color: white;
  mix-blend-mode: difference;
  z-index: 99999999999;
  pointer-events: none;
}

/* Home Styling */
.home-nav {
  display: flex;
  flex-direction: row-reverse;
  width: 90%;
  justify-content: space-between; 
}

.home-nav-btn {
  padding-top: 20px;
}

.logo-container {
  display: flex;
  justify-content: center;
  background-color: transparent;
}

.logo {
  max-width: 75%;
  height: auto;
  background-color: transparent;
  margin-top: -10%;
  /*
  width: 75%;
  height: auto;
  opacity: 1;
  animation: fade 4s linear;
  background-color: transparent;
  margin-top: -150px;
  */
}
/* Home Styling Ends */

/* Toggle & Theme Styling */
.theme-togg-container {
  padding-top: 20px;
  padding-left: 25px;
}

.toggle-container {
  height: 40px;
  width: 80px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 5px;
  cursor: pointer;
  transition: all .3s;
  /*animation: fadeIn 8s linear;*/
}

.handle {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: grid;
  align-items: center;
  justify-items: center;
  overflow: hidden;
}

.icon {
  color: #f88748;
}

.dark {
  --body-bg-color: black;
  --font-color: white;
  --alt-bg-color: purple;
  --border-color: #0D3046;
}

.light {
  --font-color: #0D3046;
  --body-bg-color: white;
  --alt-bg-color: rgba(33, 129, 193, 1);
  font-weight: bold; 
  --border-color: rgba(33, 129, 193, 1);
}

.blue {
  justify-content: flex-end;
  background-image: radial-gradient(circle farthest-corner at 10% 20%, rgba(33, 129, 193, 1) 0%, rgba(108, 229, 232, 1) 100.2%);
  height: 40px;
  width: 80px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 5px;
  cursor: pointer;
  transition: all .3s;
  /*animation: fadeIn 8s linear;*/
}

.purple {
  justify-content: flex-start;
  background-image: linear-gradient(109.8deg, rgba(62,5,116,1) -5.2%, rgba(41,14,151,1) -5.2%, rgba(216,68,148,1) 103.3%);
  height: 40px;
  width: 80px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 5px;
  cursor: pointer;
  transition: all .3s;
  /*animation: fadeIn 8s linear;*/
}
/* Toggle & Theme Styling Ends */

/* Nav Styling */
.menu-btn {
  position: absolute;
  padding-top: 0px;
  z-index: 1;
  border-radius: 10px;
  border-color: var(--font-color);
  transition: transform .7s ease-in-out;
  color: var(--font-color);
  opacity: 1;
  /*animation: fadeIn 8s linear;*/
  z-index: 7;
}

.menu-btn:hover {
  border-radius: 50%;
  transform: rotate(180deg);
}

.lines-container {
  padding: 5px;
  background-color: transparent;
}

.menu-line1, .menu-line2, .menu-line3, .contact-line1, .contact-line2 {
  width: 35px;
  height: 2px;
  background-color: var(--font-color);
  margin: 10px 0;
  transition: 0.4s;
}

.change .menu-line1 {
  transform: translate(0, 13px) rotate(-45deg);
}

.change .menu-line2 {
  opacity: 0;
}

.change .menu-line3 {
  transform: translate(0, -11px) rotate(45deg);
}

.change {
  padding: 5px;
  background-color: transparent;
}

.navigation-container {
  width: 100%;
  height: 100%;
  padding: 100px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transition: 3s;
  z-index: 5;
}

.navigation-links-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navigation-links {
  color: var(--font-color);
  margin-top: 25px;
  margin-bottom: 45px;
  margin-top: 45px;
  font-size: 20px;
}

.navigation-links:hover {
  font-weight: 800;
}

.hidden-nav{
  visibility: hidden;
  width: 100%;
}

.line {
  border-bottom: 1px solid white;
  width: 75%;
  animation: draw-line 5s;
  animation-fill-mode: forwards;
}

.arrowup-icon-hide{
  visibility: hidden;
}

.arrowup-icon {
  width: 60px;
  height: 60px;
  margin-right: 25px;
  margin-bottom: 20px;
  background-color: transparent;
  bottom: 0;
  /*animation: fadeIn 8s linear;*/
}

.arrowup-icon-container {
  display: flex;
  justify-content: flex-end;
}
/* Nav Styling */

/* Animations */
@keyframes reveal {
  0% {opacity:0;width:0%;}
  20% {opacity:1;width:0%px;}
  30% {width:355px;}
  80% {opacity:1;}
  100% {opacity:1;width:355px;}
}

@keyframes slideIn1 {
  0% { margin-left:-800px; }
  20% { margin-left:-800px; }
  35% { margin-left:0px; }
  100% { margin-left:0px; }
}

@keyframes draw-line {
  0% {
    width: 0;
  }
  50% {
    border-bottom: 1px solid blue;
  }
  100% {
    width: 75%;
    border-bottom: 1px solid var(--font-color);
  }
}

@keyframes draw-line2 {
  0% {
    width: 0;
    opacity: 0;
  }
  50% {
    border-bottom: 2px solid blue;
    border-top: 2px solid blue;
    opacity: 0.75;
  }
  100% {
    width: 75%;
    border-bottom: 2px solid var(--font-color);
    border-top: 2px solid var(--font-color);
    opacity: 1;
  }
}

@keyframes draw-line3 {
  0% {
    width: 0;
    opacity: 0;
  }
  50% {
    border-bottom: 2px solid blue;
    border-top: 2px solid blue;
    opacity: 0.75;
  }
  100% {
    width: 75%;
    border-bottom: 2px solid var(--body-bg-color);
    border-top: 2px solid var(--body-bg-color);
    opacity: 1;
  }
}

@keyframes draw-line-bottom {
  0% {
    width: 0;
    
  }
  50% {
    border-bottom: 2px solid blue;
    
  }
  100% {
    width: 75%;
    border-bottom: 2px solid var(--body-bg-color);
    
  }
}

@keyframes draw-line-bottom2 {
  0% {
    width: 0;
    opacity: 0;
  }
  50% {
    border-bottom: 2px solid blue;
    opacity: 0.75;
  }
  100% {
    width: 75%;
    border-bottom: 2px solid blue;
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.50;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }
  50% {
    opacity: 0.2
  }
  75% {
    opacity: 0.4
  }
  100% {
    opacity: 1
  }
}

@keyframes slideIn {
  from {
    margin-left: 100%;
    width: 300%;
    opacity: 0;
  }
  to {
    margin-left: 0%;
    width: 100%;
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    margin-left: 100px;
  }
  50% {
    margin-left: 400px;
  }
  100% {
    margin-left:800px;
  }
}


@keyframes rotateText {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}


@keyframes slideUp {
  0% {
      opacity: 0;
      transform: translateY(100%);
    }
    50% {
      transform: translateY(50%);
      opacity: 0.5;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    
    }
}

@keyframes slideUp2 {
  0% {
      opacity: 1;
      transform: translateY(100%);
    }
  25% {
      opacity: 1;
      transform: translateY(25%);
  }
  50% {
      transform: translateY(50%);
      opacity: 1;
    }
  100% {
      transform: translateY(0%);
      opacity: 1;
    }
}

@keyframes slide-up3 {
  0% {
    transform: translateY(100px)
  }
  100% {
    transform: translateY(0)
  }
}

@keyframes moveText {
  0% { bottom: -0.2em; opacity: 1; }
  50% { bottom: 0.2em; }
  100% { bottom: 0; opacity: 1; }
}

@keyframes slideFromRight {
  0% {
    transform: translateY(100%)
  }
  50% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes slideDown {
  0% 
    {
      opacity: 0;
      transform: translateY(-200px);
    }
  70% 
    {
      opacity: 1;
      transform: translateY(50px)
    }
  100% 
    {
      opacity: 1;
      transform: translateY(auto);
    } 
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes move-text {
  0% { bottom: -0.2em; opacity: 1; }
  50% { bottom: 0em; }
  100% { bottom: 0; opacity: 1; }
}

@keyframes move-text2 {
  0% { bottom: -4em; opacity: 1; }
  /*50% { bottom: 0.5em; }*/
  100% { bottom: 0em; opacity: 1; }
}
/* Animations End */

/* About Styling */ 
.people-icon {
  width: 100%;
}

.about-text-title {
  text-align:center;
}

.about-em, .about-emphasize {
  font-weight: 800;
}

.scd-text {
  color: #6CE5E8;
  font-weight: bold;
}

.about-container {
  padding-bottom: 100px;
}

.about-content {
  padding: 25px 25px 100px 25px;
}

.about-image-container {
  display: flex;
  justify-content: center;
}

.about-links-container {
  width: 100%;
  display: block;
  border-style: solid;
  border-color: var(--font-color);
  border-radius: 100px;
  padding: 50px;
  margin-bottom: 10px;
  color: var(--font-color);
  background-color: var(--body-bg-color);
  font-size: 24px;
  text-transform: uppercase;
  text-align: center; 
}

.about-links-icon {
  background-color: transparent;
  float: left;
}

.about-icon-reverse {
  background-color: transparent;
  margin-right: 600px;
  transform: rotate(-90deg);
  transition-delay: 3s;
}

.about-content1-hidden {
  visibility: hidden;
}

.about-content1 {
  position: relative;
  visibility: visible;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  border-style: solid;
  border-color: var(--font-color);
  padding: 50px;
}

.about-links-container:hover {
  background-color: var(--font-color);
  color: var(--body-bg-color);
}

.about-button-hide { 
  animation: slideOut 3s linear;
  animation-fill-mode: forwards;
  overflow: hidden;
  width: 100%;
  display: block;
  border-style: solid;
  border-color: var(--font-color);
  border-radius: 100px;
  padding: 40px;
  margin-bottom: 10px;
  color: var(--font-color);
  background-color: var(--body-bg-color);
  font-size: 24px;
}

.about-emphasize {
  font-size: 2vw;
  line-height: 1.5vw;
  background-color: transparent;
}

.about-learn-more {
  padding-top: 200px;
}
/* About Styling Ends */ 

/* Link Styling */
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
/* Link Styling Ends */

/* Test Early Styling */
.test-early-container, .warning-signs-container {
  padding-bottom: 50px;
}

.warning-signs-title-container {
  padding-bottom: 50px;
  padding-top: 50px;
  position: relative;
}

.test-early-title, .warning-signs-title {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: slideUp 1s linear;
  font-weight: 0;
  font-size: 5rem;
  background-color: transparent;
  
}
/* Test Early Styling Ends */

/* Warning Signs Styling */
.warning-signs-title {
  z-index: 3;
  position: relative;
  background-color: transparent;
  text-align: center;
}

.warning-signs-image {
  z-index: 1;
  background-color: transparent;
}

.warning-signs-content {
  z-index: 2;
  background-color: transparent;
}
/* Warning Signs Ends */

/* Warning Signs and Test Early Content Styling */
.motion-div-p > p {
  background-color: transparent;
  width: 200px;
  height: 200px;
  display: grid;
  align-items: center;
  padding: 25px;
  color: var(--body-bg-color);
  font-weight: 600;
}

.motion-div-p {
  opacity: 0;
  margin-top: 25px;
  background-color: var(--font-color);
  border-radius: 400px;
  height: 200px;
  width: 200px;
  margin: 10px;
  text-align: center;
  animation: slideDown 2s forwards;
}

.motion-div-p:nth-child(2) {
  animation-delay: 1s;
}

.motion-div-p:nth-child(4) {
  animation-delay: 1.5s;
}

.motion-div-p:nth-child(6) {
  animation-delay: 2s;
}

.motion-div-p:nth-child(8) {
  animation-delay: 2.5s;
}

.motion-div-p:hover {
  scale: 1.5;
  transition: 1s;
  
}

.motion-div-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.motion-div-source {
  font-size: 8px;
  text-align: center;
}

.motion-div-source-link {
  text-decoration: none;
  color: var(--font-color);
}

.motion-div-source-link::after {
  text-decoration: none;
}
/* Warning Signs and Test Early Content Styling */

/* Contact Styling */
.hidden-contact {
    visibility: hidden;
    height: 0px;
}

.contact-show {
  width: 100%;
  padding: 100px;
  position: absolute;
  z-index: 999999999;
  overflow-x: hidden;
  transition: 3s;
  margin-bottom: 100%;
 
}

.contact-container {
  padding-bottom: 100px;
  color: var(--body-bg-color);
}

.contact-close-btn {
  border: none;
  margin-bottom: 50px;
}

.contact-line1 {
  transform: translate(0, 6px) rotate(-45deg);
  background-color: var(--font-color);
}

.contact-line2 {
  transform: translate(0, -6px) rotate(45deg);
}

.contact-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/* Contact Styling Ends */

/* Our Vision Styling */

.vision-container {
  padding-bottom: 100px;
}

.vision-text-container {
  color: var(--font-color);
}
/* Vision Styling Ends */

/* Services Styling */
.services-container, .providers-container {
 padding-bottom: 100px;
}

.services-button {
  border-radius: 100px;
  padding: 25px;
}

.neurons-image {
  width: 100%;
  height: auto;
  animation: slideIn 4s linear;
}

.early-detection-container {
  display: flex;
  justify-content: center;
}

.services-list {
  list-style: none; 
}
/* Services Styling Ends */

/* Numbers Styling */
.numbers-source {
  font-size: 8px;
  padding: 10px;
}
.numbers-line {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  width: 100%;
}

.numbers-content1 {
  padding-left: 100px;
  padding-right: 100px;
}

.numbers-subheading1 {
  text-align: center;
}

.numbers-em {
  font-weight: 900;
  font-size: 8vw;
}

.numbers-em1 {
  font-weight: bolder;
  background-color: transparent;
  color: var(--body-bg-color);
  text-transform: uppercase;
  font-size: 8vw;
}

.numbers-div1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background-color: transparent;
}

.numbers-section1 {
  display: flex;
  flex-direction: column; 
  align-items: center;
  padding-bottom: 100px;
}

.numbers-circle1 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 1px solid var(--font-color); 
  background-color: var(--font-color);
  margin-bottom: 50px; 
}

.numbers-circle1-text {
  width: 200px;
  height: 200px;
  font-size: 14px;
  text-align: center;
  background-color: transparent;
  padding: 40px;
  color: var(--body-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.numbers-text {
  align-items: center;
  margin-left: 50px;
}

.numbers-circle2 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 1px solid var(--body-bg-color); 
  background-color: var(--body-bg-color);
}

.numbers-circle2-text {
  width: 200px;
  height: 200px;
  font-size: 14px;
  text-align: center;
  background-color: transparent;
  padding: 40px;
  color: var(--font-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.numbers-div2-content {
  padding: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.numbers-div2-text {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--body-bg-color);
  margin-left: 25px;
  font-size: 24px;
}

.numbers-card {
  width: 100%;
  height: 80vh;
}

#numbers1 {
  background-color: black;
  position: fixed;
}

#numbers2 {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0%;
  display: flex;
  padding-top: 27rem;
  padding-bottom: 1rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

#numbers3 {
  background-color: black;
}

.numbers-icon2 {
  background-color: #A4B4C5;
}

.percentage-icon2 {
  background-color: #A4B4C5;
}

.numbers-section3 {
  background-color: #A4B4C5;
  color: var(--body-bg-color);
}

.numbers-p {
  font-size: 24px;
  margin-left: 25px;
}

.numbers-last-heading {
  margin-top: 400px;
  padding: 50px;
}
/* Numbers End */

/* Footer Styling */
.footer-container {
  padding-top: 100px;
  padding-bottom: 0px;
  background-color: var(--font-color);
}

.footer-content {
  display: flex;
  flex-direction: row;
  background-color: var(--font-color);
  padding: 25px;
}

.footer-links {
  background-color: var(--font-color);
  color: var(--body-bg-color);
  font-size: 12px;
  text-decoration: none;
  padding: 5px;
  text-align: center;
}

.footer-links:hover {
  font-weight: 800;
}

.footer-links:visited {
  text-decoration: none;
}

.footer-x {
  background-color: transparent;
  color: var(--body-bg-color);
}
/* Footer Ends */

/* Privacy Styling */
.privacy-policy-container {
  padding: 100px;
  background-color: var(--body-bg-color);
}

.privacy-policy-content {
  color: var(--font-color);
}

.back-arrow-icon {
  width: 40px;
  height: 40px;
  color: var(--font-color);
}
/* Privacy Styling Ends*/

/* Numbers Cards */
.section {
  position: relative;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.section.s-hero {
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  margin-top: 0rem;
  padding-top: 10rem;
  padding-bottom: 7.5rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--body-bg-color);
  -webkit-transform: translate3d(0px, 0px, 10px);
  transform: translate3d(0px, 0px, 10px);
  color: var(--font-color);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  padding: 100px;
}

.section.s-radius {
  padding-top: 0rem;
  padding-bottom: 15rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-bottom-left-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

.section.s-heading {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 22.5rem;
  padding-bottom: 1rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.section.s-heading-first {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 125rem;
  padding-bottom: 1rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.card-content2 {
  position: relative;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.heading-xl {
  margin-top: 0px;
  font-size: 5rem;
  line-height: 0.95;
  font-weight: 400;
  letter-spacing: -0.05em;
  font-weight: 600;
}

.text-center {
  text-align: center;
}

.section-radius {
  position: absolute;
  left: 0%;
  top: 100%;
  right: 0%;
  z-index: 1;
  width: 100%;
  height: 5rem;
  margin-top: -1px;
  border-bottom-left-radius: 5rem;
  border-bottom-right-radius: 5rem;
  background-color: var(--body-bg-color);
}

.main {
  position: relative;
  height: 100%;
}

.background-white {
  background-color: var(--font-color);
  color: var(--body-bg-color);
}

.background-blurple {
  background-color: #A4B4C5;
}

.container-max {
  position: relative;
  width: 100%;
  max-width: 100rem;
  margin-right: auto;
  margin-left: auto;
}

.z-9 {
  position: relative;
  z-index: 9;
  -webkit-transform: translate3d(0px, 0px, 9px);
  transform: translate3d(0px, 0px, 9px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.z-7 {
  position: relative;
  z-index: 7;
  -webkit-transform: translate3d(0px, 0px, 7px);
  transform: translate3d(0px, 0px, 7px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.c-body {
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: -0.02em;
}

.scroll-text {
  top: 0;
  position: absolute;
}

.scroll-text span {
  position: absolute;
  left: 50%;
  transform-origin: 0 60px;
  background: transparent;
}

.scroll-down-container {
  margin-bottom: 200px;
  position: relative;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotateText 7s linear infinite;
  margin-top: -50px;
}

.hidden-offcanvas {
  visibility: hidden;
}

.close-offcanvas {
  background-color: transparent;
  border: none;
  width: 60px;
  height: 60px;
  padding-top: 25px;
}

.offcanvas {
  background-color: var(--body-bg-color);
}

.offcanvas-backdrop.show {
  opacity: 1;
}

.offcanvas-backdrop.fade {
  opacity: 1;
}

.offcanvas-backdrop {
  background-color: var(--body-bg-color);
}

.offcanvas-header {
  flex-direction: row-reverse;
}

.close-line1, .close-line2{
  width: 30px;
  height: 2px;
  background-color: var(--body-bg-color);
  transition: 0.4s;
  transform: translate(0, 2px) rotate(45deg);
}

.close-line1 {
  transform: translate(0, 4px) rotate(-45deg);
}

.numbers-title {
  animation: slideUp 1s linear;
  margin-top: 200px;
}

/* About motion div styling */
.motion-div1 {
  margin-right: 100px;
}

.motion-div-top {
 background-color: var(--font-color);
 border-top-left-radius: 60px ;
 border-top-right-radius: 60px;
}

.motion-div-content {
  border-radius: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: var(--font-color);
  padding-bottom: 25px;
}

.motion-div-title {
  background-color: transparent;
  color: var(--body-bg-color);
  font-size: 36px;
  margin-top: 25px;
  padding: 40px;
}

.motion-close-container {
  display: flex;
  justify-content: flex-start;
  margin-left: 40px;
}

/* Providers Styling */
.arrowup-icon2 {
  width: 60px;
  height: 60px;
  background-color: transparent;
  bottom: 0;
  animation: fadeIn 8s linear;
  margin-bottom: 25px;
}

.btn-close {
  width: 2em;
  height: 2em;
}

.providers-hover {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  width: 100px;
  height: 100px;
  border-radius: 50px; 
  border: 2px solid var(--font-color);
  background-color: var(--font-color);
  color: var(--body-bg-color);
}

.providers-hover:hover {
  background-color: transparent;
  color: var(--font-color);
}

.providers-subtext-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 800px;
  
  margin-left: 50px;
  margin-right: 50px;
}

.providers-subtext {
  background-color: transparent;
  z-index: 8;
}

.providers-hidden-text-container {
  width: 100%;
  padding-bottom: 0px;
  position: absolute;
  
  z-index: 90;
}

.providers-hidden-text {
  font-size: 2vw;
  padding: 100px;
  margin-bottom: 300px;
}

.providers-span1 {
 font-size: 5rem;
}

.providers-em {
  animation: slideUp 3s forwards;
}

.providers-subtext h3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: transparent;
}

.providers-subtext h3 div span {
  animation: slideIn 4s forwards;
  opacity: 0;
}

.providers-span2 {
  padding-right: 10px;
  font-size: 5vw;
  font-weight: 200;
  font-style: italic;
}

.providers-span3 {
  line-height: 4.5vw;
  margin-top: 0px;
  line-height: 0.95;
  letter-spacing: -0.05em;
  font-weight: 600;
  
}

.providers-span3 h3 div span {
  position: relative;
  animation: move-text2 2s forwards;
  opacity: 0;
  transition: opacity .1s cubic-bezier(.15,.9,.34,.95),transform 1.1s cubic-bezier(.04,.07,.05,.99);
}

.providers-span-text {
  padding-right: 15px;
  background-color: transparent;
  font-size: 7vw;
}

.providers-preview {
  text-decoration: none;
}

.providers-button, .services-preview-link {
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: 7px;
  background-color: var(--font-color);
  color: var(--body-bg-color);
  border: 1px solid var(--font-color);
}

.providers-icon {
  background-color: transparent;
}

.providers-span h1 span span {
  position: relative;
  opacity: 0;
  animation: move-text 1s forwards;
  animation-iteration-count: 1;
}

.providers-span {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  line-height: 0.95;
  padding: 100px;
  margin-top: 200px;
  position: absolute;
  z-index: 999;
  width: 100%;
}

.providers-content1-container {
  display: flex;
  flex-direction: row;
}

.providers-content1 {
  display: flex;
  flex-direction: column;
  padding: 0px 100px 0px;
}

.providers-content1 p {
  opacity: 0;
  animation: fadeIn 1s forwards;
}


.brainview-demo {
  padding-top: 50px;
  height: 600px;
  width: auto;
  background-color: transparent;
  transition: transform 1.5s; 
}

.brainview-demo:hover {
  transform: scale(1.5);
  cursor: -moz-zoom-in; 
  cursor: -webkit-zoom-in; 
  cursor: zoom-in;
}

.brainview-demo-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  padding-left: 0px !important;
}

.cursor-text {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--body-bg-color);
  margin-top: 20px;
  font-weight: 300;
  text-transform: uppercase;
}

.providers-image1 {
  position: absolute;
  z-index: 0;
}

.providers-image2 {
  padding-top: 50px;
  width: 80vw;
  height: auto;
  background-color: transparent;
}

.providers-image3{
  width: 80vw;
  height: auto;
  background-color: transparent;
  margin-bottom: -50px;
}

.providers-image4 {
  width: 80vw;
  height: auto;
  background-color: transparent;
}

.providers-image12 {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  margin-bottom: 400px;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: hidden;
}

.providers-back-image {
  position: absolute;
  z-index: 7;
  top: 50;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  height: auto;
  
}

.providers-title {
  background-color: transparent;
  display: flex;
  justify-content: center;
  font-size: 5rem;
}

.providers-title3 {
  background-color: transparent;
  font-weight: 900;
  font-size: 8vw;
}

.providers-text {
  background-color: transparent;
  font-weight: 600;
  text-align: left;
}

.providers-in-practice {
  background-color: #A4B4C5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.providers-drawline {
  margin-top: 50px;
  margin-bottom: 50px;
}

.providers-title2 {
  background-color: transparent;
  font-size: 3rem;
  font-weight: 600;
  color: #0D3046;
  line-height: 3rem;
  margin-left: 100px;
  margin-right: 100px;
}

.providers-title2-container {
  background-color: transparent;
}

.providers-title2-em {
  background-color: transparent;
  font-size: 5rem;
  line-height: 3rem;
  font-weight: 800;
  color: white;
}

.providers-title4 {
  font-size: 6vw;
  font-weight: 600;
}

.transparent-bkg {
  background-color: transparent;
}

.img-size {
  width: 70vw;
  height: auto;
}

.img-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-large-white {
  font-size: 8vw;
  color: white;
  text-align: center;
}

.title-sub-white {
  font-size: 3vw;
  color: white;
}

.padding-top {
  padding-top: 200px;
}

.icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
/* Providers Styling Ends */

/* TESTING NEW STYLING HERE */
.title-container {
  background-color: var(--border-color);
  color: white;
  padding-bottom: 25px;
  padding-top: 25px;
  
}

.titles {
  background-color: transparent;
  margin-left: 25px;
  color: white;
}

.title-borders {
  border-bottom: 2px solid white;
  width: 25%;
  margin-left: 25px;
}

.em-content {
  padding: 50px;
  border-bottom: 2px solid white;
  border-top: 2px solid white;
  width:50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-padding {
  padding:25px;
}

.z1-transparent {
  z-index: 1;
  background-color: transparent;
}

/* Mobile Responsiveness */
@media only screen and (max-width: 600px) {
  * {
    cursor: auto;
  }
  .cursor-dark {
    display: none;
  }
  p {
    font-size: 4vw;
    font-weight: 400;
  }
  .titles {
    margin-left: 25px;
  }
  .title-borders {
    margin-left: 25px;
  }
  .home-container {
    display: flex;
    flex-direction: column;
  }

  .home-nav {
    width: 85%;
  }

  .home-nav-btn {
    margin-right: 25px;
  }

  .logo {
    top: 10%;
    height: auto;
    width: 100%;
    margin-left: 0;
    margin-top: 10%;
    margin-bottom: 100%; 
  }

  .logo-container {
    text-align: center;
  }

  .navigation-links-container {
    margin-top: 100px;
  }
  .navigation-container {
    padding-bottom: 100%;
    overflow: hidden;
    padding: 50px;
  }
  .arrowup-icon {
    opacity: 1;
    margin-left: 75vw;
    margin-bottom: 40px;
  }
  .people-icon, .percentage-icon, .percentage-icon2, .lessthanhalf-icon, .thirtyfive-icon {
    width: 200px;
  }
  .numbers-div1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
  }
  .numbers-content1 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .numbers-circle1 {
    text-align: center;
    margin-top: 25px;
  }
  .numbers-text {
    text-align: center;
    margin-left: 0px;
    margin-bottom: 25px;
  }
  .numbers-section3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .numbers-div2-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .numbers-div2-text {
    text-align: center;
    padding-top: 25px;
    margin-left: 0px;
  }
  .numbers-p {
    text-align: center;
    padding-bottom: 25px;
    margin-left: 0px;
  }
  .numbers-last-heading {
    margin-top: 200px;
    padding: 25px;
  }
  .section {
    padding: 25px;
  }
  .section.s-hero {
    padding: 25px;
  }
  .about-links-container {
    padding: 25px;
  }
  .about-text-title {
    font-size: 20px;
  }
  .about-emphasize {
    font-size: 6vw;
  }
  .change {
    background-color: transparent; 
  }
  .early-detection-image {
    width: 225px;
    height: auto;
  }
  .about-container, .contact-container, .services-container, .vision-container, .providers-container {
    padding: 0px;
    padding-bottom: 100px;
  }
  .contact-show {
    padding: 50px;
  }
  .section.s-heading-first {
    padding-top: 150rem;
    
  }
  .motion-div {
    padding: 10px;
    width: 75vw;
  }
  .motion-div-p {
    height: 150px;
    width: 150px; 
  }
  .motion-div-p > p {
    width: 150px;
    height: 150px;
    font-size: 12px;
    padding: 10px;
    font-weight: 600;
  }
  .motion-div-source-link {
    font-size: 10px;
  }
  .providers-span {
    margin-bottom: 600px;
    padding: 50px;
    text-align: center;
    margin-top: 200px;
  }
  .providers-span1 {
    font-size: 14vw;
  }
  .providers-hidden-text-container {
    width: 100%;
  }
  .providers-hidden-text {
    font-size: 4vw;
    padding: 50px;
    margin-top: 200px;
  }
  .providers-span-text {
    display: flex;
    padding-right: 10px;
    font-size: 4rem;
  }
  .providers-subtext-container {
    justify-content: space-around;
    padding-top: 200px;
    margin-bottom: 200px;
    
  }
  .providers-image12 {
    height: 500px;
  }
  .providers-back-image {
    margin-top: 600px;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 800px;
    height: auto;
  }
  .providers-title2-em {
    font-size: 12vw;
    line-height: 8vw;
  }
  .providers-title2-container {
    background-color: transparent;
  }
  .providers-title2 {
    margin-left: 50px;
    margin-right: 50px;
  }
  .providers-span2 {
    font-size: 10vw;
  }
  .providers-image3, .providers-image2 {
    width: 100vw;
    height: auto;
  }
  .providers-content1 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .icon-container {
    display: flex;
    flex-direction: column;
  }
  .brainview-demo {
    height: 400px;
    width: auto;
  }
  .brainview-demo:hover {
    transform: scale(1.5);
  }
  .privacy-policy-container {
    padding: 50px;
  }
  .footer-x {
    padding: 10px;
  }
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px;
  }
  .footer-container {
    padding-top: 0px;
  }
}

@media only screen and (device-width: 768px) {
  /* For general iPad layouts */
  * {
    cursor: auto;
  }
  .cursor-dark {
    display: none;
  }
  .home-container {
    display: flex;
    flex-direction: column;
  }
  .logo-container {
    text-align: center;
    margin-bottom: 100%;
  }
  .contact-show {
    padding: 100px
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
  /* For portrait layouts only */
  * {
    cursor: auto;
  }
  .cursor-dark {
    display: none;
  }
  .home-container {
    display: flex;
    flex-direction: column;
  }
  .logo-container {
    margin-bottom: 100%;
    margin-top: 25%;
  }
  .navigation-container {
    padding-top: 25%;
  }
  .contact-show {
    padding: 100px;
  }
  .providers-span2 {
    font-size: 12vw;
  }
  .providers-subtext-container {
    justify-content: space-evenly;
    margin-top: 1100px;
  }
  .providers-span {
    margin-bottom: 900px;
  }
  .providers-hidden-text {
    padding-bottom: 500px;
    margin-top: 0px;
  }

}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
  /* For landscape layouts only */
  * {
    cursor: auto;
  }
  .cursor-dark {
    display: none;
  }
  .home-container {
    display: flex;
    flex-direction: row;
  }
  .providers-span {
    margin-bottom: 900px;
  }
  .providers-span2 {
    font-size: 5vw;
  }
}